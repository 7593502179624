/**
 * @see {@link https://docs.google.com/document/d/14ehVurHPoVYtEQHQtuwAf1dOuncmDj0uNH8PxBUaOOI/edit?tab=t.0}
 */
export const BREVO_LISTS = {
  CONTENT_MARKETING: 26,
  CUSTOMER_MARKETING: 22,
  STRATEGY_MARKETING: 22,
  OUTBOUND_GROWTH: 20,
  BECOME_MEMBER: 15,
  CONTACT: 14,
  SAV: 5,
};
